import React from 'react';
import { Col, Form } from 'antd';

export default function ItemCol(props) {
  const { span = 12, rules = [], offset = 0, ...itemProps } = props;
  rules.forEach((x) => {
    if (x.required && !x.message) {
      x.message = `${props.label}不能为空`;
    }
  });

  return (
    <Col span={span} offset={offset}>
      <Form.Item rules={rules} {...itemProps}>
        {props.children}
      </Form.Item>
    </Col>
  );
}
