import React, { useContext } from 'react';
import styles from './BasicLayout.module.css';
import { get, last } from 'lodash';
import { message } from 'antd';
import { LINKS, TIMING_URL, TIMING_WHITE_LIST } from '../constants/enums';
import FOOTER_LOGO from 'statics/images/home/footerLogo.png';
import TEL from 'statics/images/home/tel.png';
import FAX from 'statics/images/home/fax.png';
import QR_CODE from 'statics/images/attentionWER.jpg';
import { useVisible } from 'hooks';
import Timing from 'components/Timing';
import { _ssoLogin, _login } from './_api';
import { Auth } from 'utils';
import GlobalContext from 'globalContext';

export default function PageFooter() {
  const { $jpsUserId, $currentCity, $cityCode, $userId } = useContext(GlobalContext);
  const [timingVisible, _switchTimingVisible] = useVisible();

  // 计时平台登录
  async function _handleTiming() {
    if ($jpsUserId) {
      const res = await _ssoLogin({ cityCode: $cityCode, userId: $userId });
      if (get(res, 'resultCode') === '0000') {
        window.open(get(res, 'resultData'));
        return null;
      }
      _switchTimingVisible();
    } else {
      _switchTimingVisible();
    }
  }

  return (
    <>
      {timingVisible && <Timing onCancel={_switchTimingVisible} />}

      <div className={styles.footIntroduce}>
        <div className={styles.footMain}>
          <div className={styles.footerLogo}>
            {<img src={FOOTER_LOGO} alt="" style={{ width: 228 }} />}
          </div>
          <div className={styles.footerInfo}>
            <div>
              <div>产品中心</div>
              <div>
                <div>
                  <div
                    onClick={() => {
                      const currentCityCode = get(last($currentCity), 'code', '000000'); // 当前选择地区的CityCode

                      if (get(TIMING_URL, `${currentCityCode}`)) {
                        if (TIMING_WHITE_LIST.includes(currentCityCode) && Auth.isAuthenticated()) {
                          _handleTiming();
                        } else {
                          window.open(get(TIMING_URL, `${currentCityCode}`));
                        }
                        return;
                      }
                      message.warning('当前地区暂未开通计时平台，请切换地区后再尝试');
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.aLink}
                    href={LINKS.TIMER}
                  >
                    计时平台
                  </div>
                </div>
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.aLink}
                    href={LINKS.ERP}
                  >
                    驾校管理系统
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div>联系我们</div>
              <div className={styles.contactWay}>
                <div>
                  <span>{<img src={TEL} alt="" style={{ width: 14 }} />} </span>
                  <span>电话</span>
                  <span>0571-28080500</span>
                </div>
                <div>
                  <span>{<img src={FAX} alt="" style={{ width: 14 }} />}</span>
                  <span>传真</span>
                  <span>0571-86696433</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.qrCode}>{<img src={QR_CODE} alt="" style={{ width: 105 }} />}</div>
        </div>
      </div>
      <div className={styles.footWords}>
        <div>公司地址：中国杭州滨江区滨康路669号 邮编：310053</div>
        <div>Copyright&nbsp;&copy;&nbsp;浙江维尔科技有限公司 2021&nbsp;&nbsp;版权所有</div>
        <div>
          <a className={styles.aLink} href={LINKS.ZHE_RECORD}>
            浙ICP备09003683号-9
          </a>
        </div>
      </div>
    </>
  );
}
