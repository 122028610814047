import { useState, useEffect } from 'react';
import get from 'lodash/get';

export const useFetch = ({
  request,
  query = {},
  depends = [],
  callback = () => {},
  requiredFields = [],
}) => {
  const [res, setRes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let didCancel = false;
    // 如果没有传入必填字段则不触发请求
    if (requiredFields.some((field) => query[field] == null)) {
      return;
    }

    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const res = await request(query);
        if (!didCancel) {
          setIsError(!get(res, 'success'));
          setRes(res);
          callback(get(res, 'resultData'));
        }
      } catch (error) {
        if (!didCancel) {
          setIsError(true);
        }
      }
      if (!didCancel) {
        setIsLoading(false);
      }
    };

    fetchData();
    return () => (didCancel = true);
  }, depends);

  return { res, isLoading, isError, data: get(res, 'resultData') || undefined };
};
