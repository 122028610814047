import React from 'react';
import STAR from 'statics/images/home/star.png';
import STAR_ACTIVE from 'statics/images/home/starActive.png';

export function Star(props) {
  const { starNum = 0 } = props;
  return (
    <>
      {Array.from({ length: starNum }, (_, i) => {
        return <img src={STAR_ACTIVE} alt="" key={i} style={{ marginRight: 4 }} />;
      })}
      {Array.from({ length: 5 - starNum }, (_, i) => {
        return <img src={STAR} alt="" key={i} style={{ marginRight: 4 }} />;
      })}
    </>
  );
}
