export const Auth = {
  isAuthenticated() {
    return localStorage.getItem('token') !== null;
  },

  set(key, value) {
    localStorage.setItem(key, value);
  },

  get(key) {
    return localStorage.getItem(key);
  },

  del() {
    localStorage.clear();
  },
  clearToken() {
    localStorage.removeItem('token');
  },
};
