import { useState } from 'react';

export function useVisible() {
  const [visible, setVisible] = useState(false);

  function _switchVisible() {
    setVisible(!visible);
  }

  return [visible, _switchVisible];
}
