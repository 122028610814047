import { message } from 'antd';
import { get } from 'lodash';
import { formatQueryParams } from 'utils/formatQueryParams';
import { PORTAL_URL } from 'constants/env';

const needFailedFeedbackRequest = [];
const needFeedBackRequest = [];

export async function request(path, query = {}, method = 'GET') {
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
  let url = PORTAL_URL + path;

  const options = {
    method,
    // credentials: 'include',
    // mode: 'cors',
    headers,
  };

  if (method === 'POST') {
    Object.assign(options, { body: JSON.stringify(query) });
  }

  if (method === 'GET') {
    url = PORTAL_URL + path + formatQueryParams(query);
  }

  return fetch(url, options)
    .then(parseJSON)
    .then((response) => showFeedback(response, path))
    .catch((error) => {
      console.log(error);
    });
}

function showFeedback(response, path) {
  if (needFeedBackRequest.includes(path)) {
    if (get(response, 'success')) {
      message.success(get(response, 'msg'));
    } else {
      message.error(get(response, 'msg'));
    }
  }

  if (needFailedFeedbackRequest.includes(path)) {
    if (!get(response, 'success')) {
      message.error(get(response, 'msg'));
    }
  }

  return response;
}

function parseJSON(response) {
  return response.json();
}
