export const LINKS = {
  TIMER: 'http://www.5u5u5u5u.com/home.action', //计时平台 【TODO链接暂时不清楚，需要修改链接地址】
  WU_YOU: 'http://www.5u5u5u5u.com/home.action', // 无忧理论培训平台
  WU_YOU_LOGIN: 'https://www.5u5u5u5u.com/studentLogin.action', // 无忧登录页
  ERP: 'http://erp.zhaojl.cn', // 驾校管理系统
  ZHE_RECORD: 'https://beian.miit.gov.cn/', // 浙公网安备
  MANAGEMENT: 'http://sys.zhaojl.cn/uni/login', // 后台管理系统

  // 【科目一交规】
  SUB1_CHAPTER: 'http://www.5u5u5u5u.com/exercise/zjlx.action?typeId=56652645', // 章节练习
  SUB1_ORDER: 'http://www.5u5u5u5u.com/exercise/sxlx.action?typeId=56652645&inOrder=1&f=1', // 顺序练习
  SUB1_RANDOM: 'http://www.5u5u5u5u.com/exercise/sjlx.action?typeId=56652645&inOrder=0&f=2', // 随机练习
  SUB1_OPTION: 'http://www.5u5u5u5u.com/exercise/fxlx.action?typeId=56652645&f=3', // 分项练习
  SUB1_EXAM: 'http://www.5u5u5u5u.com/exercise/mnks.action?typeId=56652645&f=4', // 模拟考试

  // 【科目四安全文明驾驶】
  SUB4_CHAPTER: 'http://www.5u5u5u5u.com/exercise/zjlx.action?typeId=56653282', // 章节练习
  SUB4_ORDER: 'http://www.5u5u5u5u.com/exercise/sxlx.action?typeId=56653282&inOrder=1&f=1', // 顺序练习
  SUB4_RANDOM: 'http://www.5u5u5u5u.com/exercise/sjlx.action?typeId=56653282&inOrder=0&f=2', // 随机练习
  SUB4_OPTION: 'http://www.5u5u5u5u.com/exercise/fxlx.action?typeId=56653282&f=3', // 分项练习
  SUB4_EXAM: 'http://www.5u5u5u5u.com/exercise/mnks.action?typeId=56653282&f=4', // 模拟考试

  // 产品与服务
  ERP_STUDENT: 'https://android.myapp.com/myapp/detail.htm?apkName=com.qx.student&ADTAG=mobile', // 维尔驾服学员版
  ERP_COACH: 'https://android.myapp.com/myapp/detail.htm?apkName=com.qx.coach&ADTAG=mobile', // 维尔驾服教练版
};

export const BANNER_DATA = [
  { value: 1999, label: '公司成立时间', unit: '年' },
  { value: 798, label: '累计服务学员', unit: '万' },
  { value: 5108, label: '合作驾校', unit: '+' },
  { value: 20108, label: '助力教练', unit: '+' },
  { value: 108, label: '专利成果', unit: '+' },
];

export const TIMING_URL = {
  330100: 'http://115.238.28.138:8060/', // 杭州项目（独立版）
  330600: 'http://119.3.5.53:13306/login.do?reqCode=init', // 绍兴4G项目
  330900: 'http://61.153.218.132:8100', // 舟山3G项目
  331000: 'http://202.91.245.28:9999/', // 台州4G项目
  330300: 'http://202.91.245.28:9999/', // 温州标准版项目
  331100: 'http://202.91.245.28:8001/', // 丽水4G项目
  330700: 'http://202.91.245.28:28306/', // 金华4G项目
  330800: 'http://202.91.245.28:25453/index.do?reqCode=indexInit', // 衢州标准版项目
  330500: 'http://202.91.245.28:35769', // 湖州标准版项目
  330400: 'http://www.wrexc.com:23304', // 嘉兴4G项目
  370100: 'http://jinan.welldriver.cn:13701', // 济南4G项目
  370200: 'http://jxpt.welldrive.cn:30000', // 青岛4G项目
  360300: 'http://112.35.32.128:23600', // 萍乡4G项目
  360400: 'http://112.35.32.128:23600', // 九江4G项目
  361000: 'http://112.35.32.128:23600', // 抚州4G项目
  460100: 'http://jxpt.welldrive.cn:30000', // 海口4G项目
  130400: 'http://jxpt.welldrive.cn:30000', // 邯郸4G项目
  410300: 'http://jxpt.welldrive.cn:30000', // 洛阳4G项目
  411700: 'http://jxpt.welldrive.cn:30000', // 驻马店4G项目
  410400: 'http://jxpt.welldrive.cn:30000', // 平顶山4G项目
  451100: 'http://jxpt.welldrive.cn:30000', // 贺州4G项目
  450900: 'http://222.217.240.254:7004/login.do?reqCode=init', // 玉林4G项目
  441600: 'http://jxpt.welldrive.cn:30000', // 河源4G项目
  441300: 'http://jxpt.welldrive.cn:30000', // 惠州4G项目
  451000: 'http://jxpt.welldrive.cn:30000', // 百色4G项目
  410400: 'http://202.91.245.27:14104', // 平顶山4G项目（代理商）
  441400: 'http://14.18.40.4:14414/login.do?reqCode=init', // 梅州4G项目
  340100: 'http://jxpt.welldrive.cn:30000', // 合肥4G项目
  341000: 'http://jxpt.welldrive.cn:30000', //黄山3G项目
  620600: 'http://jxpt.welldrive.cn:30000', // 武威4G项目
};

export const TIMING_WHITE_LIST = ['330100'];
