import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import React from 'react';
import Loadable from 'react-loadable';
import BasicLayout from 'layouts/BasicLayout';
import { Loading } from 'components';
import { PUBLIC_URL } from 'constants/env';

export const routes = [
  {
    component: ({ route }) => <>{renderRoutes(route.routes)}</>,
    routes: [
      // {
      //   path: PUBLIC_URL,
      //   exact: true,
      //   component: Loadable({
      //     loader: () => import('pages/home'),
      //     loading: Loading,
      //   }),
      // },
      {
        path: `${PUBLIC_URL}login`,
        exact: true,
        component: Loadable({
          loader: () => import('pages/login'),
          loading: Loading,
        }),
      },
      {
        path: PUBLIC_URL,
        component: ({ route }) => <BasicLayout>{renderRoutes(route.routes)}</BasicLayout>,
        routes: [
          {
            path: `${PUBLIC_URL}`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/home'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}product`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/product'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}school`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/school'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}coach`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/coach'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}about`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/about'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}schoolDetail/:id`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/schoolDetail'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}coachDetail/:id`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/coachDetail'),
              loading: Loading,
            }),
          },
          {
            path: `${PUBLIC_URL}enlistPay`,
            exact: true,
            component: Loadable({
              loader: () => import('pages/enlistPay'),
              loading: Loading,
            }),
          },
        ],
      },
    ],
  },
];
