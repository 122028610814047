import { request } from 'services';

// 区域服务
// http://192.168.192.132:3000/project/118/interface/api/5113
export function _getAreaAuto(query) {
  return request('/portal/area/auto', query, 'GET');
}

// 获取省市县树形结构
// http://192.168.192.132:3000/project/118/interface/api/9054
export function _getAreaTree(query) {
  return request('/portal/area/tree', query, 'GET');
}

// 计时登录接口
// http://192.168.192.132:3000/project/11/interface/api/3601
export async function _login(query) {
  return request('/portal/login/loginForPortal', query, 'POST');
}

// 获取计时登录信息
// http://192.168.192.132:3000/project/11/interface/api/3594
export async function _ssoLogin(query) {
  return request('/portal/login/ssoLoginForPortal', query, 'GET');
}
