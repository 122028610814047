// 图片地址
export const IMG_URL = window.REACT_APP_IMG_URL || process.env.REACT_APP_IMG_URL;

// 用户中心地址
export const USER_CENTER_URL =
  window.REACT_APP_USER_CENTER_URL || process.env.REACT_APP_USER_CENTER_URL;

// 前端地址
export const LOCAL_URL = window.location.origin;

// 接口地址
export const API_URL = window.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

// 门户接口地址
export const PORTAL_URL = window.REACT_APP_PORTAL_URL || process.env.REACT_APP_PORTAL_URL;

export const PUBLIC_URL = window.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL;
