import { message } from 'antd';
import moment from 'moment';
import { get } from 'lodash';
import { Auth } from 'utils/auth';
import { formatQueryParams } from 'utils/formatQueryParams';
import { USER_CENTER_URL } from 'constants/env';

const needFeedBack4PostRequest = [
  '/user',
  '/role',
  '/api/usercenter/user/password/set',
  '/api/usercenter/user',
];
const needFailedFeedback = ['/api/usercenter/user/search'];

export async function postRequest(path, query = {}) {
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
  if (Auth.isAuthenticated()) {
    Object.assign(headers, {
      Authorization: 'bearer' + Auth.get('token'),
      username: Auth.get('username'),
      companyId: Auth.get('companyId'),
    });
  }

  const options = {
    method: 'POST',
    body: JSON.stringify(query),
    headers,
  };

  return fetch(USER_CENTER_URL + path, options)
    .then(parseJSON)
    .then((response) => showFeedback(response, path, 'POST'))
    .catch((error) => {});
}

export async function putRequest(path, query = {}) {
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
  if (Auth.isAuthenticated()) {
    Object.assign(headers, {
      Authorization: 'bearer' + Auth.get('token'),
      username: Auth.get('username'),
      companyId: Auth.get('companyId'),
    });
  }

  const options = {
    method: 'PUT',
    body: JSON.stringify(query),
    headers,
  };

  return fetch(USER_CENTER_URL + path, options)
    .then(parseJSON)
    .then((response) => showFeedback(response, path, 'PUT'))
    .catch((error) => {});
}

export async function getRequest(path, query = {}, other = {}) {
  if (!Auth.isAuthenticated()) {
    return;
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (Auth.isAuthenticated()) {
    Object.assign(headers, {
      Authorization: 'bearer' + Auth.get('token'),
      username: Auth.get('username') || get(other, 'username'),
      companyId: Auth.get('companyId'),
    });
  }

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(USER_CENTER_URL + path + formatQueryParams(query), options)
    .then(checkStatus)
    .then(parseJSON)
    .then((response) => showFeedback(response, path, 'GET'))
    .catch((error) => {});
}

export async function deleteRequest(path, query = {}) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const options = {
    method: 'DELETE',
    headers,
  };

  if (Auth.isAuthenticated()) {
    Object.assign(headers, {
      Authorization: 'bearer' + Auth.get('token'),
      username: Auth.get('username'),
      companyId: Auth.get('companyId'),
    });
  }

  return fetch(USER_CENTER_URL + path + formatQueryParams(query), options)
    .then(checkStatus)
    .then(parseJSON)
    .then((response) => showFeedback(response, path, 'DELETE'))
    .catch((error) => {});
}

export async function postRequestTemp(path, query = {}) {
  const headers = { Accept: 'application/json', 'Content-Type': 'application/json' };
  if (Auth.isAuthenticated()) {
    Object.assign(headers, { token: Auth.get('token') });
  }

  const options = {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers,
  };

  return fetch(USER_CENTER_URL + path + formatQueryParams(query), options)
    .then(parseJSON)
    .then((response) => showFeedback(response, path, 'POST'))
    .catch((error) => {});
}

/**
 *
 * @param {*} path
 * @param {*} query
 * @param {*} fileName
 */
export async function downloadRequest(path, query = {}, fileName = 'temp') {
  const headers = {
    Accept: 'text/html, application/xhtml+xml, image/jxr, */*',
    'Content-Type': 'application/json',
  };

  if (Auth.isAuthenticated()) {
    Object.assign(headers, { token: Auth.get('token') });
  }

  const options = {
    method: 'GET',
    headers,
  };

  return fetch(USER_CENTER_URL + path + formatQueryParams(query), options)
    .then(parseBlob)
    .then((blob) => {
      try {
        const blobUrl = window.URL.createObjectURL(blob);

        // IE11兼容模式
        if (window.navigator.msSaveBlob) {
          window.navigator.msSaveBlob(
            blob,
            fileName + '-' + moment().format('YYYY-MM-DD') + '.xls',
          );

          return;
        }

        const a = document.createElement('a');
        a.download = fileName + '-' + moment().format('YYYY-MM-DD') + '.xls';
        a.href = blobUrl;
        a.click();
      } catch (e) {}
    })
    .catch((error) => {});
}

// 将 ReadableStream 转为blob return Promise
function parseBlob(response) {
  return response.blob();
}

function showFeedback(response, path, method) {
  // code -900 为token过期
  if (get(response, 'code') === -900) {
    // message.error(get(response, "msg"));
    Auth.del();
    return;
  }

  if (method === 'DELETE' || method === 'PUT') {
    if (get(response, 'code') === 200) {
      message.success(get(response, 'message'));
    } else {
      message.error(get(response, 'message'));
    }
  }

  if (method === 'POST') {
    if (needFeedBack4PostRequest.includes(path)) {
      if (get(response, 'code') === 200) {
        message.success(get(response, 'message'));
      } else {
        message.error(get(response, 'message'));
      }
    }
  }

  if (method === 'GET') {
    const purePath = path.replace(/\?.*/, '');

    if (needFailedFeedback.includes(purePath)) {
      if (get(response, 'code') !== 200) {
        message.error(get(response, 'message'));
      }
    }
  }

  return response;
}

function parseJSON(response) {
  return response.json();
}

// TODO:
function checkStatus(response) {
  // return response;

  const status = get(response, 'status');
  if ((status >= 200 && status < 300) || status === 304) {
    return response;
  }

  if (status === 401) {
    // Auth.del();  不注释缓存没了要重新注册
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}
