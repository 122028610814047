import React from 'react';
import { Modal, Form, Input, Button, Message, Space } from 'antd';
import { get } from 'lodash';
import { Auth } from 'utils';

import LOGO from 'statics/images/home/logo.png';

export default function LoginModal(props) {
  const { isRegister, onCancel } = props;
  const [form] = Form.useForm();
  return (
    <Modal
      title={isRegister ? '注册' : '登录'}
      visible
      onCancel={() => onCancel()}
      width={400}
      footer={null}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          margin: '20px 0',
        }}
      >
        <Space align="center">
          <span style={{ fontWeight: 'bold', fontSize: '24px', color: 'black' }}>
            欢迎{isRegister ? '注册' : '登录'}{' '}
          </span>
          <span style={{ fontSize: '24px', color: 'black' }}>|</span>
          <img
            src={LOGO}
            alt=""
            style={{ width: 110 }}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          />
        </Space>
      </div>

      <Form form={form} autoComplete="off">
        <Form.Item
          name="userName"
          rules={[
            { whitespace: true, required: true, message: '请输入手机号码' },
            {
              pattern: /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
              message: '输入正确的手机号码',
            },
          ]}
        >
          <Input
            placeholder="请输入手机号码"
            style={{ margin: 'auto', display: 'block' }}
            size="large"
            maxLength={400}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { whitespace: true, required: true, message: '请输入密码' },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d)(.{6,20})$/,
              message: '密码长度为6-20位,必须包含字母和数字，字母区分大小写',
            },
          ]}
        >
          <Input
            style={{ margin: 'auto', display: 'block' }}
            placeholder="请输入密码"
            type="password"
            size="large"
            maxLength={400}
          />
        </Form.Item>

        {/* 注册的时候需要一个确认密码的框 */}
        {isRegister && (
          <Form.Item
            name="passwordConfirm"
            rules={[
              { whitespace: true, required: true, message: '请输入确认密码' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次密码不一致'));
                },
              }),
            ]}
          >
            <Input
              style={{ margin: 'auto', display: 'block' }}
              placeholder="请确认密码"
              type="password"
              size="large"
              maxLength={400}
            />
          </Form.Item>
        )}
      </Form>
      <Button
        type="primary"
        size="large"
        maxLength={300}
        block
        shape="round"
        onClick={() => {
          form.validateFields().then((values) => {
            if (isRegister) {
              Auth.set('userName', get(values, 'userName'));
              Auth.set('password', get(values, 'password'));
              Message.success('注册成功');
            } else {
              if (
                Auth.get('userName') === get(values, 'userName') &&
                Auth.get('password') === get(values, 'password')
              ) {
                Auth.set('token', get(values, 'userName'));
                window.location.reload(); // 登录的时候刷新一下当前页面
              } else {
                Message.error('用户名或密码错误');
              }
            }
            onCancel();
          });
        }}
      >
        {isRegister ? '注册' : '登录'}
      </Button>
    </Modal>
  );
}
