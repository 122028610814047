import { useState } from 'react';

export function usePagination(pageSize = 10, total = 1, current = 1) {
  const [pagination, setPagination] = useState({
    pageSize,
    total,
    current,
  });

  return [pagination, setPagination];
}
