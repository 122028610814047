import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Modal, Popover } from 'antd';
import { Auth } from 'utils';
import { postRequestTemp } from 'services/userCenter';
import { getRequest } from 'services/userCenter';
import styles from './BasicLayout.module.css';
import { useFetch, useVisible } from 'hooks';
import { get } from 'lodash';
import LoginModal from 'components/LoginModal';
import { USER_CENTER_URL, LOCAL_URL } from 'constants/env';
import GlobalContext from 'globalContext';
import ATTENTION_IMG from 'statics/images/attentionWER.jpg';
import TOP_ICON from 'statics/images/topIcon.png';
import PHONE_ICON from 'statics/images/phoneIcon.png';
import CUSTOM_ICON from 'statics/images/customIcon.png';
import { PUBLIC_URL } from 'constants/env';

import { FONT_SIZE_BASE, PRIMARY_COLOR } from 'constants/styleVariables';
import LOGO from 'statics/images/home/logo.png';

import PageFooter from './PageFooter';
import { _getAreaTree } from './_api';

const { Header, Content } = Layout;

export default function BasicLayout(props) {
  const history = useHistory();
  const [visible, _switchVisible] = useVisible();
  const [isRegister, setIsRegister] = useState(false); // 是否为注册 false:登录 true: 注册
  const [loginVisible, setLoginVisible] = useState(false); // false:登录注册弹框不弹出  true：弹出
  const [topVisible, setTopVisible] = useState(false);
  const {
    $setToken,
    $setSchoolId,
    $setUsername,
    $setUserIdString,
    $setRolesIds,
    $setCompanyId,
    $currentCity,
    $setCurrentCity,
  } = useContext(GlobalContext);

  const { data: areaTree = [] } = useFetch({
    request: _getAreaTree,
  });

  useEffect(() => {
    window.addEventListener('scroll', topEvent);
    function topEvent() {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        setTopVisible(true);
      } else {
        setTopVisible(false);
      }
    }
    return () => window.removeEventListener('scroll', topEvent);
  }, []);

  const PATH_ARR = [
    { path: '', title: '首页' },
    { path: 'product', title: '产品中心' },
    { path: 'school', title: '驾校' },
    { path: 'coach', title: '教练' },
    { path: 'about', title: '关于我们' },
  ];

  function _handleLogout() {
    const token = Auth.get('token');
    window.location.href = `${PUBLIC_URL}`;
    Auth.clearToken();
  }

  function _handleLogin() {
    const code = window.location.search.replace(/\?code=/, '');
    if (!Auth.isAuthenticated()) {
      if (code !== '') {
        // 如果未授权就发起获取token的请求
        postRequestTemp('/uni/oauth/token', {
          grant_type: 'authorization_code',
          client_id: 'J8Kq169h56tm',
          client_secret: 'dd1492ee1c6e2c0cc72f963134534375',
          code,
          redirect_uri: `${LOCAL_URL}/login`,
        }).then(async (res) => {
          const token = get(res, 'access_token'); // 用户中心返回的token
          Auth.set('token', token);
          $setToken(token);

          const options = {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              token,
            },
          };

          // 从用户中心获取username
          const uniUserRes = await fetch(USER_CENTER_URL + '/uni/user', options);
          const bar = await uniUserRes.json();
          Auth.set('username', get(bar, 'username'));
          $setUsername(get(bar, 'username'));

          // 使用username从用户中心获取用户信息
          const userInfoRes = await getRequest(
            '/api/usercenter/user/info/front',
            {},
            { username: get(bar, 'username') },
          );

          if (get(userInfoRes, 'code') === 200) {
            const companies = get(userInfoRes, 'data.companies', []);
            const companyId = get(userInfoRes, 'data.companyId', '');
            const selectedCompany = companies.find((x) => (x.companyId = companyId));
            const rolesIds = get(userInfoRes, 'data.companyRoles', [])
              .map((x) => x.id)
              .join(',');

            Auth.set('schoolId', get(selectedCompany, 'companyCode'));
            $setSchoolId(get(selectedCompany, 'companyCode'));

            Auth.set('companyId', companyId);
            $setCompanyId(companyId);

            Auth.set('userIdString', get(userInfoRes, 'data.id'));
            $setUserIdString(get(userInfoRes, 'data.id'));

            Auth.set('rolesIds', rolesIds);
            $setRolesIds(rolesIds);
          }
        });
      } else {
        window.location.href =
          `${USER_CENTER_URL}` +
          '/uni/oauth/authorize' +
          '?client_id=' +
          'J8Kq169h56tm' +
          '&response_type=code&redirect_uri=' +
          `${LOCAL_URL}/login`;
      }
      return;
    }
  }

  function _handleClick(item, index) {
    history.push(`${PUBLIC_URL}${item.path}`);
  }

  const ATTENTION = (
    <div style={{ textAlign: 'center' }}>
      <img src={ATTENTION_IMG} style={{ width: 120 }} />
      <div style={{ color: '#aaa', margin: '5px 0' }}>关注我们</div>
    </div>
  );

  return (
    <>
      <Modal
        title="切换城市"
        visible={visible}
        onCancel={_switchVisible}
        width={600}
        footer={null}
        bodyStyle={{ maxHeight: 400, overflow: 'scroll' }}
      >
        {areaTree.map((x) => (
          <div key={get(x, 'code')} style={{ display: 'flex', lineHeight: 2, marginBottom: 16 }}>
            <div
              onClick={() => {
                $setCurrentCity([x]);
                _switchVisible();
              }}
              style={{ width: 100, flexShrink: 0, cursor: 'pointer' }}
            >
              {get(x, 'name')}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {get(x, 'children').map((y) => (
                <div
                  onClick={() => {
                    $setCurrentCity([x, y]);
                    _switchVisible();
                  }}
                  key={get(y, 'code')}
                  style={{ marginRight: 12, cursor: 'pointer' }}
                >
                  {get(y, 'name')}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Modal>

      <div style={{ position: 'fixed', bottom: 100, right: '5%', zIndex: 100 }}>
        {topVisible && (
          <div
            className={styles.bottomBox}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <img className={styles.bottomIcon} src={TOP_ICON} />
            返回顶部
          </div>
        )}

        <Popover
          content={ATTENTION}
          placement="left"
          trigger="hover"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <div className={styles.bottomBox}>
            <img className={styles.bottomIcon} src={PHONE_ICON} />
            关注我们
          </div>
        </Popover>

        <div
          className={styles.bottomBox}
          onClick={() => window.open('http://www.zhaojl.cn/appIndex.html')}
        >
          <img className={styles.bottomIcon} src={CUSTOM_ICON} />
          下载客户端
        </div>
      </div>

      <Layout
        className="ie-basic-layout-wrapper"
        style={{ minHeight: '100vh', background: 'rgba(248,248,248,1)', minWidth: 1200 }}
      >
        <Header
          style={{
            padding: 0,
            background: '#fff',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
            width: '100%',
          }}
        >
          <div className={styles.headerBox}>
            <div style={{ flex: 2 }}>
              <span
                style={{ marginRight: 20 }}
                onClick={() => {
                  history.push(`${PUBLIC_URL}`);
                }}
              >
                {
                  <img
                    src={LOGO}
                    alt=""
                    style={{ width: 110 }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      });
                    }}
                  />
                }
              </span>
              {/* 去掉切换城市  2021.3.19 */}
              {/* <span onClick={_switchVisible} style={{ cursor: 'pointer' }}>
                <span style={{ fontSize: 12 }}>
                  {get($currentCity, '1.name') || get($currentCity, '0.name')}
                </span>
                <span style={{ fontSize: 12 }}>&lt;切换&gt;</span>
              </span> */}
            </div>
            <div className={styles.navTab} style={{ fontSize: FONT_SIZE_BASE, flex: 3 }}>
              {PATH_ARR.map((item, index) => {
                return (
                  <span
                    key={index}
                    onClick={() => _handleClick(item, index)}
                    className={
                      get(history, 'location.pathname') === PATH_ARR[index].path
                        ? styles.tabLIne
                        : null
                    }
                  >
                    {item.title}
                  </span>
                );
              })}
            </div>
            <div style={{ flex: 2, textAlign: 'right' }}>
              <span style={{ marginRight: 20, fontSize: 18 }}>
                服务热线：
                <span style={{ color: PRIMARY_COLOR, fontWeight: 'bold', fontSize: 18 }}>
                  0571－28080500
                </span>
              </span>
              {!Auth.isAuthenticated() && (
                <>
                  <span
                    onClick={() => {
                      setLoginVisible(true);
                      setIsRegister(false);
                    }}
                    style={{ cursor: 'pointer', marginRight: 20 }}
                  >
                    登录
                  </span>
                  <span
                    onClick={() => {
                      setLoginVisible(true);
                      setIsRegister(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    注册
                  </span>
                </>
              )}

              {Auth.isAuthenticated() && (
                <span onClick={_handleLogout} style={{ cursor: 'pointer' }}>
                  退出
                </span>
              )}
            </div>
          </div>
        </Header>
        <Content style={{ marginTop: 64 }} className="ie_content_bg">
          {props.children}
        </Content>
        <PageFooter />
      </Layout>
      {loginVisible && (
        <LoginModal
          isRegister={isRegister}
          onCancel={() => {
            setLoginVisible(false);
          }}
        />
      )}
    </>
  );
}
